import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import { GlobalStyle } from '../theme/GlobalStyle';
import '../style/all.scss';
import CopyRight from './CopyRight';

const TemplateWrapper = ({ children }) => (
  <>
    <GlobalStyle />
    <Helmet title="Home | Dr Sameiee Official Website" />
    <Header />
    {children}
    <Footer />
    <CopyRight />
  </>
);

export default TemplateWrapper;
