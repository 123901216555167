export const LINKS = {
  home: '/',
  about: '/about-us',
  medicines: '/medicines',
  diseases: '/diseases',
  faqs: '/faqs',
  blogs: '/blogs',
  contact: '/contact-us',
  appointment: '/appointment',
};
