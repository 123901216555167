import React from 'react';
import styled from 'styled-components';
import { Grid, Cell, colors, device } from '../theme';
import { Container } from 'styled-bootstrap-grid';
import { Link } from 'gatsby';
import { H1 } from './Title';
import { H4 } from './Title';
import { ICONS, IconLoader } from './IconLoader';
import { LINKS } from '../routes';
import { Menu } from './Menu';
import { NavLink } from './NavLink';

const HeaderWrapper = styled.header`
  background: ${colors.gray.headerBackground};
  padding: 1rem;
`;

const TopHeader = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.gray.borderBottom};
  justify-content: space-between;
`;

const SocialList = styled.ul`
  display: flex;
  align-items: center;
  justify-items: center;
  color: ${colors.gray.headerItemColor};
  justify-content: ${(props) => (props.left ? 'flex-end' : 'flex-start')};
`;

const SocialListItem = styled.li`
  padding: 0.3rem;
  > a {
    display: flex;
    color: ${colors.gray.headerItemColor};
    :hover {
      color: ${colors.blue.linkHover};
    }
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const Logo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.dark ? colors.blue.lightSubTitr : colors.blue.darkTitr};

  @media ${device.tablet} {
    justify-content: flex-start;
  }
`;

const LogoTitle = styled.div`
  font-size: 3rem;
  font-weight: 900;
`;

const LogoSubTitle = styled.div`
  font-size: 1.8rem;
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <Container>
        <TopHeader>
          <SocialList>
            <SocialListItem>
              <a className="tel" href="tel:09359359947">
                ۰۹۳۵-۹۳۵۹۹۴۷
              </a>
            </SocialListItem>
            <SocialListItem>
              <a href="https://www.facebook.com/mahmood.samei" target="_blank">
                <IconLoader name={ICONS.facebook} />
              </a>
            </SocialListItem>
            <SocialListItem>
              <a href="mailto:info@drmahmoodsamei.com">
                <IconLoader name={ICONS.email} />
              </a>
            </SocialListItem>
          </SocialList>
          <SocialList left>
            <SocialListItem>
              <a href="https://wa.me/989122207244" target="_blank">
                <IconLoader name={ICONS.whatsapp} />
              </a>
            </SocialListItem>
            <SocialListItem>
              <a href="https://t.me/Dr_samei" target="_blank">
                <IconLoader name={ICONS.telegram} />
              </a>
            </SocialListItem>
            <SocialListItem>
              <a
                href="https://www.instagram.com/drmahmoodsamei/"
                target="_blank"
              >
                <IconLoader name={ICONS.instagram} />
              </a>
            </SocialListItem>
          </SocialList>
        </TopHeader>
        <Grid rowGap={'1.5rem'} rows={1} columns={'30% 70%'}>
          <Cell>
            <Logo to={LINKS.home}>
              <LogoTitle>دکتر محمود سامعی</LogoTitle>
            </Logo>
            <Logo dark="true" to={LINKS.home}>
              <LogoSubTitle>متخصص رادیوتراپی و انکولوژی</LogoSubTitle>
            </Logo>
          </Cell>
          <Cell middle center>
            <Menu />
          </Cell>
        </Grid>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;
