import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Cell, colors, device } from '../theme';
import { Container } from 'styled-bootstrap-grid';
import { H1 } from './Title';
import { H4 } from './Title';
import { Link } from 'gatsby';
import { ICONS, IconLoader } from './IconLoader';
import { ArrowDown } from './ArrowDown';
import { NavLink } from './NavLink';
import { LINKS } from '../routes';

const Navigator = styled.div`
  .menu-icon {
    cursor: pointer;
    padding: 28px 20px;
    position: relative;
    user-select: none;
    display: flex;
    justify-content: center;
  }
  .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }

  .menu-icon .navicon:before,
  .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }
  .menu-icon .navicon:before {
    top: 5px;
  }
  .menu-icon .navicon:after {
    top: -5px;
  }

  .menu-btn {
    display: none;
  }

  .menu-btn:checked ~ .menu {
    max-height: 240px;
  }

  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }

  .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }

  @media ${device.tablet} {
    .menu-icon {
      display: none;
    }
  }
`;

const MenuWrapper = styled.ul`
  justify-content: center;
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  align-items: center;
  justify-items: center;
  color: ${colors.gray.headerItemColor};
  visibility: ${(props) => (props.isExpanded ? 'visible' : 'hidden')};

  @media ${device.tablet} {
    justify-content: flex-end;
    display: flex;
    visibility: visible;
  }
`;

const SubMenue = styled.a`
  line-height: 1em;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const SubMenueDropdown = styled.ul`
  list-style: none;
  padding: 22px 0;
  margin: 0;
  background-color: #efefef;
  z-index: 30;
  overflow: visible !important;
  height: auto;
  width: 100%;
  min-height: 40px;
  max-height: 3000px;
  display: none;
  position: absolute;
  border: none;
  border-radius: 0;
  border-bottom: 4px solid #4eabf9;
  text-align: center;

  @media ${device.tablet} {
    width: 30%;
  }
`;

const MenuItem = styled.li`
  padding: 0.5rem;
  &:hover ${SubMenueDropdown} {
    display: block;
  }

  @media ${device.tablet} {
    padding: 1rem;
  }
`;

const SubMenueDropdownItems = styled.li`
  display: block;
  position: relative;
  width: 100%;
  padding: 1rem;
  background: inherit;
`;

const Menu = () => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <>
      <Navigator>
        <input
          className="menu-btn"
          type="checkbox"
          id="menu-btn"
          onChange={() => setExpanded(!isExpanded)}
        />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>
        <MenuWrapper className="menu" isExpanded={isExpanded}>
          <MenuItem>
            <NavLink to={LINKS.home}>صفحه اصلی</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to={LINKS.about}>درباره دکتر</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to={LINKS.medicines}>داروها</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to={LINKS.diseases}>بیماری ها</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to={LINKS.faqs}>سوالات متداول</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to={LINKS.blogs}>مقالات</NavLink>
          </MenuItem>
          <MenuItem>
            تماس با دکتر
            <ArrowDown />
            <SubMenueDropdown>
              <SubMenueDropdownItems>
                <NavLink to={LINKS.contact}>ارتباط و مشاوره با دکتر</NavLink>
              </SubMenueDropdownItems>
              <SubMenueDropdownItems>
                <NavLink to={LINKS.appointment}>تعیین وقت و پذیرش</NavLink>
              </SubMenueDropdownItems>
            </SubMenueDropdown>
          </MenuItem>
        </MenuWrapper>
      </Navigator>
    </>
  );
};

export { Menu };
