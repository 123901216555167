import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors } from '../theme';

export const NavLink = styled(Link)`
  color: ${colors.gray.headerItemColor};
  :hover {
    color: ${colors.blue.linkHover};
  }
  svg {
    width: 2rem;
    height: 2rem;
  }
`;
