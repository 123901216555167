import React from 'react';
import styled from 'styled-components';

const ArrowDown = styled.span`
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 5px;
  transform: rotate(45deg);
`;

export { ArrowDown };
