import React from 'react';
import styled from 'styled-components';
import { Grid, Cell, colors } from '../theme';
import { Container } from 'styled-bootstrap-grid';

const CopyRightSection = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: ${colors.blue.copyRightBackground};
  color: ${colors.yellow.CopyRightFont};
  font-size: 1.3rem !important;
  font-style: italic;
`;

const CopyRight = () => {
  return (
    <CopyRightSection>
      <Container>
        <Grid rows={1} columns={1}>
          <Cell center>
            تمامی حقوق مادی و معنوی این سایت متعلق به دکتر محمود سامعی می باشد.
          </Cell>
        </Grid>
      </Container>
    </CopyRightSection>
  );
};

export default CopyRight;
