import React from 'react';
import styled from 'styled-components';
import { Grid, Cell, colors, device } from '../theme';
import { Container } from 'styled-bootstrap-grid';
import { Link } from 'gatsby';
import { H1 } from './Title';
import { H4 } from './Title';
import SeperetorTop from './Seperator-top';
import { useBlogs } from '../hooks/blogs';
import GatsbyImage from 'gatsby-image';

const FooterWrapper = styled.footer`
  background: ${colors.blue.footerBackground};
  border-color: ${colors.blue.footerBackground};
  position: relative;
  color: ${colors.blue.footerBackground};
  transition: all 300ms ease-in-out;
  padding-top: 2rem;
  padding-bottom: 2rem;
  z-index: 9;

  @media ${device.laptop} {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
`;

const FooterNav = styled.ul`
  color: ${colors.white};
  padding: 1rem;
`;

const FooterNavItem = styled.li`
  display: flex;
  align-items: center;
  svg {
    width: 2rem;
    height: 2rem;
  }
  img {
    max-width: 10rem;
    height: auto;
    width: 100%;
  }
`;

const FooterMediaList = styled.ul`
  padding-right: 0;
  list-style: none;
`;

const FooterMedia = styled.li`
  display: flex;
  position: relative;
  margin-top: 15px;
  overflow: hidden;
`;

const FooterMediaLink = styled(Link)`
  position: relative;
`;

const FooterVisitedLink = styled(Link)`
  color: #03a9f4;
  :hover {
    color: #fbc02d;
  }
`;

const FooterMediaHeading = styled.h5`
  font-weight: 700;
  padding: 2px 0px 0px 0px;
  margin: 0 15px 5px 0;
  font-size: 14px;
`;

const FooterMediaSmall = styled.small`
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px;
  text-align: center;
  width: 100%;
  z-index: 2;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.4);
`;

const FooterMediaDescription = styled.p`
  margin: 0 15px 0 0;
  font-size: 13px;
  color: ${colors.white};
  p {
    font-size: 13px;
    color: ${colors.white};
  }
`;

const LinkCustom = styled.a`
  padding: 1rem;
  color: ${colors.white};
  :hover {
    color: ${colors.blue.linkHover};
  }
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

const Logo = styled.div`
  color: ${(props) =>
    props.white
      ? colors.white
      : props.lightBlue
      ? colors.blue.footerSubLogo
      : colors.blue.darkTitr};
  padding: 1rem;
  margin-bottom: 1rem;
`;

// const SeperetorTop = styled.div`
//   position: absolute;
//   width: 100%;
//   z-index: 1;
//   top: 0;

//   ::after {
//     top: -3.1rem;
//     content: '';
//     border-top-color: transparent;
//     border-bottom-color: transparent;
//     right: 49.99%;
//     transform: rotateY(180deg);
//     position: absolute;
//     z-index: 9;
//     width: 0;
//     height: 0;
//     border-style: solid;
//     border-width: 3.2rem 0 0 90rem;
//     border-width: 3.2rem 0 0 50vw;
//     border-left-width: 90rem;
//     border-left-width: 50vw;
//   }
//   ::before {
//     border-top-color: transparent;
//     border-bottom-color: transparent;
//     left: 49.99%;
//     top: -3.1rem;
//     content: '';
//     width: 0;
//     height: 0;
//     border-style: solid;
//     border-width: 3.2rem 0 0 90rem;
//     border-width: 3.2rem 0 0 50vw;
//     border-left-width: 90rem;
//     border-left-width: 50vw;
//     position: absolute;
//     z-index: 9;
//   }
// `;

const AfterH = styled.h2`
  font-weight: 900;
  font-size: 2.5rem;
  width: 100%;
  display: flex;
  white-space: nowrap;
  ::after {
    border-top: 1px solid;
    content: '';
    display: block;
    position: relative;
    top: 0.5em;
    width: 100%;
    right: 1.5%;
  }
`;

const Footer = () => {
  const blogs = useBlogs();
  return (
    <FooterWrapper>
      {/* <SeperetorTop>
        <div style={{ display: 'block' }} />
      </SeperetorTop> */}
      <SeperetorTop />
      <Container>
        <Grid rowGap={'1.5rem'} rows={1} columns={2}>
          <Cell>
            <Logo white>
              <H1>دکتر محمود سامعی</H1>
            </Logo>
            <Logo lightBlue>
              <H4>متخصص رادیوتراپی و انکولوژی</H4>
            </Logo>
          </Cell>
          <Cell middle>
            <Logo white>
              <AfterH>آخرین مقالات</AfterH>
            </Logo>
          </Cell>
        </Grid>

        <Grid rowGap={'1.5rem'} rows={1} columns={2}>
          <FooterNav>
            {/* <FooterNavItem>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="phone-square-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm-16.39 307.37l-15 65A15 15 0 0 1 354 416C194 416 64 286.29 64 126a15.7 15.7 0 0 1 11.63-14.61l65-15A18.23 18.23 0 0 1 144 96a16.27 16.27 0 0 1 13.79 9.09l30 70A17.9 17.9 0 0 1 189 181a17 17 0 0 1-5.5 11.61l-37.89 31a231.91 231.91 0 0 0 110.78 110.78l31-37.89A17 17 0 0 1 299 291a17.85 17.85 0 0 1 5.91 1.21l70 30A16.25 16.25 0 0 1 384 336a17.41 17.41 0 0 1-.39 3.37z"
                ></path>
              </svg>
              <LinkCustom href="#">۰۲۱-۸۸۹۸۹۷۶۶</LinkCustom>
            </FooterNavItem> */}
            <FooterNavItem>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="mobile-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"
                ></path>
              </svg>
              <LinkCustom href="/pages/appointment">۰۹۳۵-۹۳۵۹۹۴۷</LinkCustom>
            </FooterNavItem>
            <FooterNavItem>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="telegram-plane"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"
                ></path>
              </svg>
              <LinkCustom href="https://t.me/Dr_samei" target="_blank">
                @Dr_samei
              </LinkCustom>
            </FooterNavItem>
            <FooterNavItem>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook-square"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                ></path>
              </svg>
              <LinkCustom
                href="https://www.facebook.com/mahmood.samei"
                target="_blank"
              >
                Mahmood Samei
              </LinkCustom>
            </FooterNavItem>
            <FooterNavItem>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="instagram"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                ></path>
              </svg>
              <LinkCustom
                href="https://www.instagram.com/drmahmoodsamei/"
                target="_blank"
              >
                drmahmoodsamei
              </LinkCustom>
            </FooterNavItem>
            <FooterNavItem>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="envelope-square"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM178.117 262.104C87.429 196.287 88.353 196.121 64 177.167V152c0-13.255 10.745-24 24-24h272c13.255 0 24 10.745 24 24v25.167c-24.371 18.969-23.434 19.124-114.117 84.938-10.5 7.655-31.392 26.12-45.883 25.894-14.503.218-35.367-18.227-45.883-25.895zM384 217.775V360c0 13.255-10.745 24-24 24H88c-13.255 0-24-10.745-24-24V217.775c13.958 10.794 33.329 25.236 95.303 70.214 14.162 10.341 37.975 32.145 64.694 32.01 26.887.134 51.037-22.041 64.72-32.025 61.958-44.965 81.325-59.406 95.283-70.199z"
                ></path>
              </svg>
              <LinkCustom target="_blank" href="mailto:info@drmahmoodsamei.com">
                info@drmahmoodsamei.com
              </LinkCustom>
            </FooterNavItem>
            <FooterNavItem>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="map-marker-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  fill="currentColor"
                  d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                ></path>
              </svg>
              <LinkCustom href="#">
                کرج, میدان شهداء, برج نوا, طبقه 4 واحد 6
              </LinkCustom>
            </FooterNavItem>
            {/* <FooterNavItem>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="map-marker-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  fill="currentColor"
                  d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                ></path>
              </svg>
              <LinkCustom href="#">
                اتوبان چمران جنوب, نرسیده به باقرخان, نبش کوچه کاج, مرکز
                رادیوتراپی امید تهران طبقه اول
              </LinkCustom>
            </FooterNavItem> */}
          </FooterNav>

          <FooterMediaList>
            {blogs.slice(0, 3).map((post) => {
              return (
                <FooterMedia key={post.wordpress_id}>
                  <div>
                    <FooterMediaLink to={`/blog/${post.wordpress_id}`}>
                      <GatsbyImage
                        fixed={
                          post.featured_media.localFile.childImageSharp.fixed
                        }
                      />
                      <FooterMediaSmall>
                        {new Date(post.date).toLocaleDateString('fa-IR')}
                      </FooterMediaSmall>
                    </FooterMediaLink>
                  </div>
                  <div>
                    <FooterMediaHeading>
                      <FooterVisitedLink to={`/blog/${post.wordpress_id}`}>
                        {post.title}
                      </FooterVisitedLink>
                    </FooterMediaHeading>
                    <FooterMediaDescription
                      dangerouslySetInnerHTML={{
                        __html: `${post.excerpt
                          .split(' ')
                          .slice(0, 20)
                          .join(' ')}...`,
                      }}
                    />
                  </div>
                </FooterMedia>
              );
            })}
          </FooterMediaList>
        </Grid>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
