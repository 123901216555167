import React from 'react';
import SVG from 'react-inlinesvg';
const ICONS = {
  facebook: 'facebook',
  email: 'email',
  telegram: 'telegram',
  instagram: 'instagram',
  whatsapp: 'whatsapp',
};

const IconLoader = ({ name }) => (
  <SVG
    loader={<span>Loading...</span>}
    cacheRequests={true}
    title={name}
    src={`/icons/${name}.svg`}
  />
);

export { IconLoader, ICONS };
