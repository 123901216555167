const API = {
  base: 'https://cms.softiware.com/drmahmoodsamei/wp-json',
  prefix: '/wp/v2',
};

const endpointUrl = `${API.base}${API.prefix}`;

export const endpoints = {
  posts: `${endpointUrl}/posts`,
  pages: `${endpointUrl}/pages`,
  media: `${endpointUrl}/media`,
  faqs: `${endpointUrl}/faqs`,
  medicines: `${endpointUrl}/medicines`,
  diseases: `${endpointUrl}/diseases`,
};

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopM: '1200px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const commonInTheme = {
  generalFonts: `'Roboto', Arial, Helvetica, sans-serif`,
  highLightFonts: `'Rubik', Arial, Helvetica, sans-serif`,
  fontSizes: {
    small: '1.2rem',
    medium: '1.6rem',
    large: '2.1rem',
    xlarge: '3rem',
  },
  borderRadius: '5px',
  defaultMarginSection: '5rem 0',
  lineHeight: 1.5,
};

export const textAlign = {
  center: 'text-align = center',
  left: 'text-align = left',
  right: 'text-align = right',
};

export const colors = {
  white: 'rgba(255, 255, 255, 1)',
  white20: 'rgba(255, 255, 255, 0.2)',
  primary: '#ff8200',
  secondary: '#f80503',
  black50: 'rgba(0, 0, 0, 0.5)',
  nostedGray: '#A6BBC8',
  darkGray222: '#222',
  darkGray333: '#333',
  grayc2c2c2: '#c2c2c2',
  gray6b6b6b: '#6b6b6b',
  grayf5f5f5: '#f5f5f5',
  grayf4f4f4: '#f4f4f4',
  grayd9e1e6: '#d9e1e6',

  black: '#000000',

  insta: {
    btn: '#C13584',
    icon: '#833AB4',
  },

  gray: {
    borderBottom: '#dddddd',
    headerBackground: '#efefef',
    headerItemColor: '#555555',
    pTagsColor: '#323232',
    blogGray: '#e5e5e5',
    formBorder: '#cbcbcb',
    veryLight: '#aaaaaa',
  },
  blue: {
    linkHover: '#4eabf9',
    darkTitr: '#304970',
    lightSubTitr: '#45a5fa',
    footerBackground: '#2c4167',
    footerSubLogo: '#acc4e9',
    copyRightBackground: '#222d3f',
    captionBlue: '#45aaff',
    buttonIconBlue: '#0079de',
    blogBtn: '#304770',
    telegramBlue: '#0088cc',
    foldedBodyOne: '#088eff',
    foldedBodyTwo: '#269cff',
    foldedBodyFour: '#64b8ff',
    formBg: '#2e6695',
  },
  yellow: {
    CopyRightFont: '#f9ce61',
    hoverFooter: '#fbc02d',
  },

  green: {
    greenButton: '#5cb85c',
    greenButtonIcon: 'green',
  },
};
