import React from 'react';
import styled from 'styled-components';
import { Grid, Cell, colors } from '../theme';
import { Container } from 'styled-bootstrap-grid';

const SeperetorTopSection = styled.div`
  position: absolute;
  background-color: inherit;
  border-color: inherit;
  width: 100%;
  z-index: 1;
  top: 0;

  ::after {
    top: -3.1rem;
    content: '';
    border-top-color: transparent;
    border-bottom-color: transparent;
    right: 49.99%;
    transform: rotateY(180deg);
    position: absolute;
    z-index: 9;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3.2rem 0 0 90rem;
    border-width: 3.2rem 0 0 50vw;
    border-left-width: 90rem;
    border-left-width: 50vw;
  }
  ::before {
    border-top-color: transparent;
    border-bottom-color: transparent;
    left: 49.99%;
    top: -3.1rem;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3.2rem 0 0 90rem;
    border-width: 3.2rem 0 0 50vw;
    border-left-width: 90rem;
    border-left-width: 50vw;
    position: absolute;
    z-index: 9;
  }
`;

const SeperetorTop = () => {
  return <SeperetorTopSection></SeperetorTopSection>;
};

export default SeperetorTop;
